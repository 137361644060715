import {
  Modal,
  Paper,
  Typography,
  Grid,
  Drawer,
  Button
} from "@material-ui/core";
import { Settings, Build, ArrowBack } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import MapDesigner from "./components/Map";
import './App.css';

const baseUrl = "https://manufay.com";

async function pullCanvasFromManufay(canvas_id) {
  const response = await fetch(`${baseUrl}/api/canvas/${canvas_id}`);
  if (!response.ok) {
    const message = `An error has occurred`;
  }
  const resdata = await response.json();
  return resdata;
}

async function pullSvgFromManufay(url) {
  const response = await fetch(url);
  if (!response.ok) {
    const message = `An error has occurred`;
  }
  const resdata = await response.text();
  return resdata;
}

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [svgTemplate, setSvgTemplate] = useState("");
  const [svgFields, setSvgFields] = useState("");
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasUnits, setCanvasUnits] = useState("");

  const openMapApp = (canvas_id) => {
    pullCanvasFromManufay(canvas_id).then((data) => {
      //after api call
      setSvgFields(JSON.parse(data.data.fields));
      setCanvasWidth(data.data.width);
      setCanvasHeight(data.data.height);
      setCanvasUnits(data.data.units);
      pullSvgFromManufay(baseUrl + data.data.canvas_svg)
        .then(data => {
          setSvgTemplate(data);
          setDrawerOpen(true);
        });
    });
  };

  useEffect(() => {
    let canvas_id = 3;
    openMapApp(canvas_id);
  }, []);

  return (
    <>
      {/* <Button onClick={() => {
         let canvas_id = 3;
          openMapApp(canvas_id);
        }}
        style={{backgroundColor: "#e0b189", maxHeight:56}}
        autoFocus>
        <Build />
        Customize
      </Button> */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Grid item container style={{ width: '100vw', height: '100vh' }}>
          <Grid item>
            <MapDesigner
              setDrawerOpen={setDrawerOpen}
              svgTemplate={svgTemplate}
              svgFields={svgFields}
              canvasWidth={canvasWidth}
              canvasHeight={canvasHeight}
              canvasUnits={canvasUnits}
              setSvgTemplate={setSvgTemplate}
              setSvgFields={setSvgFields}
            />
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

export default App;
