import {
  AppBar,
  Box,
  Hidden,
  Paper,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Tabs,
  Tab,
  TextField,
  Typography,
  Grid,
  Button
} from "@material-ui/core";
import {
  AddShoppingCart,
  ArrowBack,
  Build,
  Cancel,
  Favorite,
  FontDownload,
  Visibility,
  Search,
  Settings
} from "@material-ui/icons";
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from "react";
import { getTextboxRows, updateSVGText } from "../../utils/svgReader";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "./style.css";
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from "!@mapbox/mapbox-gl-geocoder";
mapboxgl.accessToken = 'pk.eyJ1IjoicmF3ZWJ0ZWNoc3RhY2siLCJhIjoiY2tsdjBwd3R2MjN2YzJubHdyZmE5anlqaCJ9.dogo1KD_P7le6AwHFXj6zw';
const baseUrl = "https://manufay.com";

function TabPanel(props) {
  const { children, value, setValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        height: 250,
        bottom: 72,
        backgroundColor: '#ccc',
        position: 'absolute',
        width:'100%',
        zIndex: 999
      }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
        <Fab color="secondary" onClick={() => setValue(false)} style={{float:'right'}} aria-label="edit">
          <Cancel />
        </Fab>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function downloadURI(uri, name) {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function generateBmp(genRequest) {
  console.log(genRequest);
  const response = await fetch(`${baseUrl}/api/images/store`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(genRequest)
  });
  return await response.json();
}

function MapDesigner({
  svgTemplate,
  setSvgTemplate,
  svgFields,
  canvasWidth,
  canvasHeight,
  canvasUnits,
  setSvgFields,
  setDrawerOpen
}) {
  const [open, setOpen] = React.useState(false);
  const [lng, setLng] = useState(-94.158);
  const [lat, setLat] = useState(36.06);
  const [zoom, setZoom] = useState(12);
  const [canvasDisplay, setCanvasDisplay] = useState('inherit');
  const [geocoder, setGeocoder] = useState(null);
  const [svgHeight, setSvgHeight] = useState(380);
  const [showTextBoxes, setShowTextBoxes] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [displayTextBackground, setDisplayTextBackground] = useState(true);
  const [formRows, setFormRows] = useState(getTextboxRows(svgFields));

  const [value, setValue] = React.useState(5);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const mapContainer = useRef(null);
  const svgContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {

    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/rawebtechstack/cklv2ma1r3gdq17l5h5sb8jcx',
        center: [lng, lat],
        zoom: zoom,
        preserveDrawingBuffer: true
      });
      const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
      });
      map.current.addControl(geocoder);
    }

    handleResize();

    function handleResize() {
      // should run this on every brower resize
      let svg = document.getElementById('product');
      if(svg && svg.clientHeight) {
        console.log(svg.clientHeight);
        setSvgHeight(svg.clientHeight);
        map.current.resize();
      }
    }

    window.addEventListener('resize', handleResize)

    return _ => {
          window.removeEventListener('resize', handleResize)
    }

  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const continueCheckout = () => {
    console.log('checkout');
    let image = map.current.getCanvas().toDataURL('image/png');
    console.log(image);
    let svg = document.getElementById('product');
    svg.style.width = svg.getBBox().width + 'px';
    svg.style.height = svg.getBBox().height + 'px';
    svg.style.position = 'absolute';

    let mapContainer = document.getElementById("map-container");
    mapContainer.innerHTML =
      '<img id="visual-canvas" style="width:' +
      svg.getBBox().width + 'px;height:' +
      svg.getBBox().height + 'px;" src="' +
      image +
      '" />'
    ;
    let svgContainer = document.getElementById("raw-canvas-svg");
    console.log(svgContainer.outerHTML);
    let genRequest = {
      'svg_element': svgContainer.outerHTML,
      'svg_width': svg.getBBox().width,
      'svg_height': svg.getBBox().height,
      'canvas_width': canvasWidth,
      'canvas_height': canvasHeight,
      'canvas_units': canvasUnits
    };
    // The below code will let you preview the genarated image
    //downloadURI(image, "map.png");
    generateBmp(genRequest).then((data) => {
      //after api call
      console.log(data);
      // TODO: This url needs to be set as the product image for the temp generated product at checkout
      console.log(baseUrl+data.data.url);
    });
    setDrawerOpen(false);
  };

  return (
    <>
    <div style={{
      width:'100vw'
    }} id="raw-canvas-svg">
      <div dangerouslySetInnerHTML={{ __html: svgTemplate }} style={{
        width:'100vw',
        position: 'absolute',
        zIndex: 999,
        display: canvasDisplay
      }} />
      <div id="map-container" ref={mapContainer} className="map-container" style={{
        height: svgHeight
      }}/>
    </div>
    <TabPanel value={value} setValue={setValue} index={1}>
      {/*Search Bar*/}
    </TabPanel>
    <TabPanel value={value} setValue={setValue} index={2}>
      <Typography variant='h4'>
        Map Controls
      </Typography>
      <br />
      <Typography variant='body1'>
        <b>Mobile:</b> Two finger swipe to move / pan the map. Two finger pinch / spread to zoom the map.
      </Typography>
      <Typography variant='body1'>
        <b>Desktop:</b> Single click and drag to move / pan the map. Scroll to zoom the map.
      </Typography>
    </TabPanel>
    <TabPanel value={value} setValue={setValue} index={3}>
      <Grid container id="textBoxForm" style={{
          position:'relative',
          justifyContent:'center',
        }}>
        {formRows.map((row) => (
          <Grid item sm={3}>
          <FormControl>
            <TextField
              name={row.name}
              defaultValue={row.value}
              onChange={(e) => {
                console.log(e.target.value);
                row.value = e.target.value;
                setFormRows(formRows);
                setSvgTemplate(updateSVGText(formRows, svgTemplate));
              }}
              type="text"
              label={row.label}
            />
            </FormControl>
          </Grid>
        ))}
        <Grid item sm={3}>
          <FormControlLabel
            control={
              <Switch
                checked={displayTextBackground}
                onChange={(e) => {
                  let textBox = document.getElementById('text-background');
                  setDisplayTextBackground(e.target.checked);
                  if(e.target.checked) {
                    textBox.style.display = '';
                  } else {
                    textBox.style.display = 'none';
                  }
                }}
                name="disableBackground"
              />
            }
            label="Display Text Box"
          />
        </Grid>
      </Grid>
    </TabPanel>
    <TabPanel value={value} setValue={setValue} index={4}>
    <Typography variant='h4'>
      Design Preview
    </Typography>
    <br />
    <Typography variant='body1'>
      This is what your design will look like when etched onto your selected product.
    </Typography>
    </TabPanel>
    <AppBar position="static" style={{bottom: 0, position:'fixed'}} color="default">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="action tabs example"
      >
        <Tab icon={<ArrowBack />} label={<Hidden xsDown>Exit Customizer</Hidden>}
          style={{backgroundColor:'#bdbdbd', minWidth: 26, height: 76}}
          onClick={() => setDrawerOpen(false)}
          {...a11yProps(0)}/>
        <Tab icon={<Search />} label={<Hidden xsDown>Search Location</Hidden>}
          style={{backgroundColor:'#f1c8a5', minWidth: 26, height: 76}}
          onClick={() => {
            setShowTextBoxes(false);
            var search = document.getElementsByClassName("mapboxgl-ctrl-top-right")[0];
            search.style.display = 'block';
            setShowSearch(true);
          }}
          {...a11yProps(1)}/>
        <Tab icon={<Settings />} label={<Hidden xsDown>Map Controls</Hidden>}
          style={{backgroundColor:'#ecbc92', minWidth: 26, height: 76}}
          onClick={() => {
            var search = document.getElementsByClassName("mapboxgl-ctrl-top-right")[0];
            search.style.display = 'none';
            setCanvasDisplay('none');
          }}
          {...a11yProps(2)}/>
        <Tab icon={<FontDownload />} label={<Hidden xsDown>Edit Text Box</Hidden>}
          style={{backgroundColor:'#f5bc8b', minWidth: 26, height: 76}}
          onClick={() => {
            var search = document.getElementsByClassName("mapboxgl-ctrl-top-right")[0];
            search.style.display = 'none';
            setCanvasDisplay('inherit');
            setShowTextBoxes(true);
          }}
          {...a11yProps(3)}/>
        <Tab icon={<Visibility />} label={<Hidden xsDown>Preview Design</Hidden>}
          style={{backgroundColor:'#f5b074', minWidth: 26, height: 76}}
          onClick={() => {
            var search = document.getElementsByClassName("mapboxgl-ctrl-top-right")[0];
            search.style.display = 'none';
            setCanvasDisplay('inherit');
          }}
          {...a11yProps(4)}/>
        <Tab icon={<AddShoppingCart />} label={<Hidden xsDown>Add to Cart</Hidden>}
          style={{backgroundColor:'#5ade7c', minWidth: 26, height: 76}}
          onClick={() => continueCheckout()}
          {...a11yProps(5)}/>
      </Tabs>
    </AppBar>
    </>
  );
}

export default MapDesigner;
