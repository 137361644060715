
export const getTextboxRows = (textboxes) => {
    var rows = [];
    for (let field in textboxes) {
        rows.push({
          name: field,
          label: field.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
            return str.toUpperCase();
          }),
          value: textboxes[field]
        });
    }

    return rows;
}

export const  updateSVGText = (rows, baseSVGText) => {
    /* take text from textboxes and put them into the canvas */

    let name_value = {}; // new text values
    Array.from(rows).forEach(inputElement => {
        name_value[inputElement.name] = inputElement.value ? inputElement.value : " ";
    });

    let regex = new RegExp("<text.*>(.*)</text>", "g");
    let matches = baseSVGText.match(regex);
    let numTexts = 0;
    if (matches) {
        matches.forEach(textElement => {
            try {
                numTexts++;
                let name = textElement.match(/id="([-\w]*)"/)[1].split('-').splice(1).join('-');
                if (name in name_value) {
                    let valueMatch = textElement.match(/>(.*)</);
                    let newValue = valueMatch[0].replace(valueMatch[1], name_value[name]);
                    baseSVGText = baseSVGText.replace(
                        textElement,
                        textElement.replace(valueMatch[0], newValue)
                    );

                    if(newValue == "><") {
                      numTexts--;
                    }
                }

            } catch (error) {
                console.error("could not parse", textElement);
                console.error(error);
            }
        });

        if (matches) {
            matches.forEach(textElement => {
                try {
                    numTexts++;
                    let name = textElement.match(/id="([-\w]*)"/)[1].split('-').splice(1).join('-');
                    if (name in name_value) {
                      var width = document.getElementById("bg-"+name).getBBox().width;
                      var height = document.getElementById("bg-"+name).getBBox().height;
                      var textNode = document.getElementById("field-"+name);
                      var bb = textNode.getBBox();
                      var widthTransform = width / bb.width;
                      var heightTransform = height / bb.height;
                      var value = widthTransform < heightTransform ? widthTransform : heightTransform;
                      var textContent = textNode.getAttribute("transform");
                      let matrixValues = textContent.match(/[+-]?\d+(\.\d+)?/g);
                      //console.log(value, value * 2);
                      matrixValues[0] = value;
                      matrixValues[3] = value;
                      textContent = textContent.replace(textContent, "matrix("+matrixValues.join(" ")+")");
                      textNode.setAttribute("transform", textContent);
                    }

                } catch (error) {
                    console.error("could not parse", textElement);
                    console.error(error);
                }
            });
          }

        // var new_data = JSON.stringify(name_value);
        // $('#canvas_data').val(new_data);
    }
    return baseSVGText;
}
